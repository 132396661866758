export enum DisplayEnum {
  LOADING = "LOADING",
  DETAIL_HOTEL_ROOMS = "DETAIL_HOTEL_ROOMS",
  DETAIL_HOTEL_FOODS = "DETAIL_HOTEL_FOODS",
  DETAIL_HOTEL_SOUVENIRS = "DETAIL_HOTEL_SOUVENIRS",
  SELECT_DATE = "SELECT_DATE",
  DETAIL_ROOM = "DETAIL_ROOM",
  DETAIL_CATEGORY_FOOD = "DETAIL_CATEGORY_FOOD",
  DETAIL_FOOD = "DETAIL_FOOD",
  DETAIL_CATEGORY_SOUVENIR = "DETAIL_CATEGORY_SOUVENIR",
  DETAIL_SOUVENIR = "DETAIL_SOUVENIR",
  DETAIL_REWARD = "DETAIL_REWARD",
  DETAIL_ORDER_FOOD = "DETAIL_ORDER_FOOD",
  PAYMENT_ORDER_FOOD = "PAYMENT_ORDER_FOOD",
  SUCCESS_PAYMENT_ORDER_FOOD = "SUCCESS_PAYMENT_ORDER_FOOD",
  PAYMENT_ORDER_ROOM = "PAYMENT_ORDER_ROOM",
  SUCCESS_PAYMENT_ORDER_ROOM = "SUCCESS_PAYMENT_ORDER_ROOM",
  DETAIL_ORDER_SOUVENIR = "DETAIL_ORDER_SOUVENIR",
  PAYMENT_ORDER_SOUVENIR = "PAYMENT_ORDER_SOUVENIR",
  SUCCESS_PAYMENT_ORDER_SOUVENIR = "SUCCESS_PAYMENT_ORDER_SOUVENIR",
  DETAIL_ORDER_WALLET = "DETAIL_ORDER_WALLET",
  PAYMENT_ORDER_WALLET = "PAYMENT_ORDER_WALLET",
  DETAIL_ORDER = "DETAIL_ORDER",
  EDIT_PROFILE = "EDIT_PROFILE",
  HOTEL_USERNAME = "HOTEL_USERNAME",
  SEARCH_FOOD = "SEARCH_FOOD",
  SEARCH_SOUVENIR = "SEARCH_SOUVENIR",
  DETAIL_PROMO_FOOD = "DETAIL_PROMO_FOOD",
  DETAIL_PROMO_SOUVENIR = "DETAIL_PROMO_SOUVENIR",
  VIEW_HOTEL_ROOMS = "VIEW_HOTEL_ROOMS",
}
