import { useRouter } from "next/dist/client/router";
import ErrorPage from "next/error";
import React, { useEffect, useState } from "react";
import dynamic from "next/dynamic";
import { DisplayEnum } from "../src/libs/enum/display";
import Loading from "../src/components/Loading";

const DetailHotelRooms = dynamic(
  () => import("./hotels/[hotel_username]/rooms")
);
const ViewHotelRooms = dynamic(() => import("./hotels/[hotel_username]/view"));

const DetailHotelFoods = dynamic(
  () => import("./hotels/[hotel_username]/foods")
);
const DetailHotelSouvenirs = dynamic(
  () => import("./hotels/[hotel_username]/souvenirs")
);
const DetailRoom = dynamic(
  () => import("./hotels/[hotel_username]/rooms/[room_id]")
);

const SelectDate = dynamic(
  () => import("./hotels/[hotel_username]/select-date")
);

const HotelUsername = dynamic(() => import("./hotels/[hotel_username]"));

const DetailCategoryFood = dynamic(
  () => import("./hotels/[hotel_username]/foods/category/[category_id]")
);
const DetailFood = dynamic(
  () => import("./hotels/[hotel_username]/foods/details/[food_id]")
);
const SearchFood = dynamic(
  () => import("./hotels/[hotel_username]/foods/search")
);
const DetailCategorySouvenir = dynamic(
  () => import("./hotels/[hotel_username]/souvenirs/category/[category_id]")
);
const DetailSouvenir = dynamic(
  () => import("./hotels/[hotel_username]/souvenirs/details/[souvenir_id]")
);
const SearchSouvenir = dynamic(
  () => import("./hotels/[hotel_username]/souvenirs/search")
);
const DetailReward = dynamic(() => import("./membership/reward/[reward_id]"));
const DetailOrderFood = dynamic(() => import("./orders/foods/[order_id]"));
const PaymentOrderFood = dynamic(
  () => import("./orders/foods/[order_id]/payment")
);
const SuccessPaymentOrderFood = dynamic(
  () => import("./orders/foods/[order_id]/success")
);
const PaymentOrderRoom = dynamic(
  () => import("./orders/rooms/[order_id]/payment")
);
const SuccessPaymentOrderRoom = dynamic(
  () => import("./orders/rooms/[order_id]/success")
);
const DetailOrderSouvenir = dynamic(
  () => import("./orders/souvenirs/[order_id]")
);
const PaymentOrderSouvenir = dynamic(
  () => import("./orders/souvenirs/[order_id]/payment")
);
const SuccessPaymentOrderSouvenir = dynamic(
  () => import("./orders/souvenirs/[order_id]/success")
);
const DetailOrderWallet = dynamic(() => import("./orders/wallet/[order_id]"));
const PaymentOrderWallet = dynamic(
  () => import("./orders/wallet/[order_id]/payment")
);
const DetailOrder = dynamic(() => import("./orders/[order_id]"));
const EditProfile = dynamic(
  () => import("./profile/guest-profile/[profile_id]/edit")
);

const Custom404 = () => {
  const router = useRouter();
  const [displayState, setDisplayState] = useState<DisplayEnum>(
    DisplayEnum.LOADING
  );
  const path = router.asPath.split("?")?.[0];
  const params = router.asPath.split("?")?.[1]?.substring(0);

  useEffect(() => {
    const matchDetailHotelRooms = path.match(
      /^\/hotels\/[^\/]*[a-zA-Z][^\/]*\/rooms\/?$/
    );
    const matchViewHotelRooms = path.match(
      /^\/hotels\/[^\/]*[a-zA-Z][^\/]*\/view\/?$/
    );
    const matchDetailHotelFoods = path.match(
      /^\/hotels\/[^\/]*[a-zA-Z][^\/]*\/foods\/?$/
    );
    const matchDetailHotelSouvenirs = path.match(
      /^\/hotels\/[^\/]*[a-zA-Z][^\/]*\/souvenirs\/?$/
    );
    const matchSelectDate = path.match(
      /^\/hotels\/[^\/]*[a-zA-Z][^\/]*\/select-date\/?$/
    );
    const matchHotelUsername = path.match(
      /^\/hotels\/[^\/]*[a-zA-Z][^\/]*\/?$/
    );
    const matchDetailRoom = path.match(
      /^\/hotels\/[^\/]*[a-zA-Z][^\/]*\/rooms\/[^\/]*[a-zA-Z][^\/]*\/?$/
    );
    const matchDetailCategoryFood = path.match(
      /^\/hotels\/[^\/]*[a-zA-Z][^\/]*\/foods\/category\/[^\/]*[a-zA-Z][^\/]*\/?$/
    );
    const matchDetailFood = path.match(
      /^\/hotels\/[^\/]*[a-zA-Z][^\/]*\/foods\/details\/[^\/]*[a-zA-Z][^\/]*\/?$/
    );
    const matchSearchFood = path.match(
      /^\/hotels\/[^\/]*[a-zA-Z][^\/]*\/foods\/search\/?$/
    );
    const matchDetailCategorySouvenir = path.match(
      /^\/hotels\/[^\/]*[a-zA-Z][^\/]*\/souvenirs\/category\/[^\/]*[a-zA-Z][^\/]*\/?$/
    );
    const matchDetailSouvenir = path.match(
      /^\/hotels\/[^\/]*[a-zA-Z][^\/]*\/souvenirs\/details\/[^\/]*[a-zA-Z][^\/]*\/?$/
    );
    const matchSearchSouvenir = path.match(
      /^\/hotels\/[^\/]*[a-zA-Z][^\/]*\/souvenirs\/search\/?$/
    );
    const matchDetailReward = path.match(
      /^\/membership\/reward\/[^\/]*[a-zA-Z][^\/]*\/?$/
    );
    const matchDetailOrderFood = path.match(
      /^\/orders\/foods\/[^\/]*[a-zA-Z][^\/]*\/?$/
    );
    const matchPaymentOrderFood = path.match(
      /^\/orders\/foods\/[^\/]*[a-zA-Z][^\/]*\/payment\/?$/
    );
    const matchSuccessPaymentOrderFood = path.match(
      /^\/orders\/foods\/[^\/]*[a-zA-Z][^\/]*\/success\/?$/
    );
    const matchPaymentOrderRoom = path.match(
      /^\/orders\/rooms\/[^\/]*[a-zA-Z][^\/]*\/payment\/?$/
    );
    const matchSuccessPaymentOrderRoom = path.match(
      /^\/orders\/rooms\/[^\/]*[a-zA-Z][^\/]*\/success\/?$/
    );
    const matchDetailOrderSouvenir = path.match(
      /^\/orders\/souvenirs\/[^\/]*[a-zA-Z][^\/]*\/?$/
    );
    const matchPaymentOrderSouvenir = path.match(
      /^\/orders\/souvenirs\/[^\/]*[a-zA-Z][^\/]*\/payment\/?$/
    );
    const matchSuccessPaymentOrderSouvenir = path.match(
      /^\/orders\/souvenirs\/[^\/]*[a-zA-Z][^\/]*\/success\/?$/
    );
    const matchDetailOrderWallet = path.match(
      /^\/orders\/wallet\/[^\/]*[a-zA-Z][^\/]*\/?$/
    );
    const matchPaymentOrderWallet = path.match(
      /^\/orders\/wallet\/[^\/]*[a-zA-Z][^\/]*\/payment\/?$/
    );
    const matchDetailOrder = path.match(/^\/orders\/[^\/]*[a-zA-Z][^\/]*\/?$/);
    const matchEditProfile = path.match(
      /^\/profile\/guest-profile\/[^\/]*[a-zA-Z][^\/]*\/edit\/?$/
    );
    if (matchDetailHotelRooms) {
      setDisplayState(DisplayEnum.DETAIL_HOTEL_ROOMS);
      return;
    }
    if (matchViewHotelRooms) {
      setDisplayState(DisplayEnum.VIEW_HOTEL_ROOMS);
      return;
    }
    if (matchDetailHotelFoods) {
      setDisplayState(DisplayEnum.DETAIL_HOTEL_FOODS);
      return;
    }
    if (matchDetailHotelSouvenirs) {
      setDisplayState(DisplayEnum.DETAIL_HOTEL_SOUVENIRS);
      return;
    }
    if (matchSelectDate) {
      setDisplayState(DisplayEnum.SELECT_DATE);
      return;
    }
    if (matchHotelUsername) {
      setDisplayState(DisplayEnum.HOTEL_USERNAME);
      return;
    }
    if (matchDetailRoom) {
      setDisplayState(DisplayEnum.DETAIL_ROOM);
      return;
    }
    if (matchDetailCategoryFood) {
      setDisplayState(DisplayEnum.DETAIL_CATEGORY_FOOD);
      return;
    }
    if (matchDetailFood) {
      setDisplayState(DisplayEnum.DETAIL_FOOD);
      return;
    }
    if (matchSearchFood) {
      setDisplayState(DisplayEnum.SEARCH_FOOD);
      return;
    }
    if (matchDetailCategorySouvenir) {
      setDisplayState(DisplayEnum.DETAIL_CATEGORY_SOUVENIR);
      return;
    }
    if (matchDetailSouvenir) {
      setDisplayState(DisplayEnum.DETAIL_SOUVENIR);
      return;
    }

    if (matchSearchSouvenir) {
      setDisplayState(DisplayEnum.SEARCH_SOUVENIR);
      return;
    }

    if (matchDetailReward) {
      setDisplayState(DisplayEnum.DETAIL_REWARD);
      return;
    }
    if (matchDetailOrderFood) {
      setDisplayState(DisplayEnum.DETAIL_ORDER_FOOD);
      return;
    }
    if (matchPaymentOrderFood) {
      setDisplayState(DisplayEnum.PAYMENT_ORDER_FOOD);
      return;
    }
    if (matchSuccessPaymentOrderFood) {
      setDisplayState(DisplayEnum.SUCCESS_PAYMENT_ORDER_FOOD);
      return;
    }
    if (matchPaymentOrderRoom) {
      setDisplayState(DisplayEnum.PAYMENT_ORDER_ROOM);
      return;
    }
    if (matchSuccessPaymentOrderRoom) {
      setDisplayState(DisplayEnum.SUCCESS_PAYMENT_ORDER_ROOM);
      return;
    }
    if (matchDetailOrderSouvenir) {
      setDisplayState(DisplayEnum.DETAIL_ORDER_SOUVENIR);
      return;
    }
    if (matchPaymentOrderSouvenir) {
      setDisplayState(DisplayEnum.PAYMENT_ORDER_SOUVENIR);
      return;
    }
    if (matchSuccessPaymentOrderSouvenir) {
      setDisplayState(DisplayEnum.SUCCESS_PAYMENT_ORDER_SOUVENIR);
      return;
    }
    if (matchDetailOrderWallet) {
      setDisplayState(DisplayEnum.DETAIL_ORDER_WALLET);
      return;
    }
    if (matchPaymentOrderWallet) {
      setDisplayState(DisplayEnum.PAYMENT_ORDER_WALLET);
      return;
    }
    if (matchDetailOrder) {
      setDisplayState(DisplayEnum.DETAIL_ORDER);
      return;
    }
    if (matchEditProfile) {
      setDisplayState(DisplayEnum.EDIT_PROFILE);
      return;
    }
  }, []);

  if (displayState === DisplayEnum.LOADING) {
    return (
      <div className="bg1000-white h-screen">
        <Loading open={displayState === DisplayEnum.LOADING} />
      </div>
    );
  }

  if (displayState === DisplayEnum.DETAIL_HOTEL_ROOMS) {
    const separators = ["/"];
    const newPath = path.split(
      new RegExp("[" + separators.join("") + "]", "g")
    );
    const props = JSON.parse(
      '{"' +
        decodeURI(params)
          .replace(/"/g, '\\"')
          .replace(/&/g, '","')
          .replace(/=/g, '":"') +
        '"}'
    );

    const username = newPath[2];
    return (
      <div className="bg1000-white h-screen">
        <DetailHotelRooms username={username} {...props} />
      </div>
    );
  }

  if (displayState === DisplayEnum.VIEW_HOTEL_ROOMS) {
    const separators = ["/"];
    const newPath = path.split(
      new RegExp("[" + separators.join("") + "]", "g")
    );
    const props =
      params &&
      JSON.parse(
        '{"' +
          decodeURI(params)
            .replace(/"/g, '\\"')
            .replace(/&/g, '","')
            .replace(/=/g, '":"') +
          '"}'
      );

    const username = newPath[2];
    return (
      <div className="bg1000-white h-screen">
        <ViewHotelRooms username={username} {...props} />
      </div>
    );
  }

  if (displayState === DisplayEnum.DETAIL_HOTEL_FOODS) {
    const separators = ["/"];
    const newPath = path.split(
      new RegExp("[" + separators.join("") + "]", "g")
    );
    const username = newPath[2];
    return (
      <div className="bg1000-white h-screen">
        <DetailHotelFoods username={username} />
      </div>
    );
  }

  if (displayState === DisplayEnum.DETAIL_HOTEL_SOUVENIRS) {
    const separators = ["/"];
    const newPath = path.split(
      new RegExp("[" + separators.join("") + "]", "g")
    );

    const username = newPath[2];
    return (
      <div className="bg1000-white h-screen">
        <DetailHotelSouvenirs username={username} />
      </div>
    );
  }

  if (displayState === DisplayEnum.SELECT_DATE) {
    const separators = ["/"];
    const newPath = path.split(
      new RegExp("[" + separators.join("") + "]", "g")
    );
    const username = newPath[2];
    return (
      <div className="bg1000-white h-screen">
        <SelectDate username={username} />
      </div>
    );
  }

  if (displayState === DisplayEnum.HOTEL_USERNAME) {
    const separators = ["/"];
    const newPath = path.split(
      new RegExp("[" + separators.join("") + "]", "g")
    );
    const username = newPath[2];
    return (
      <div className="bg1000-white h-screen">
        <HotelUsername username={username} />
      </div>
    );
  }

  if (displayState === DisplayEnum.DETAIL_ROOM) {
    const separators = ["/"];
    const newPath = path.split(
      new RegExp("[" + separators.join("") + "]", "g")
    );
    const id = newPath[4];
    return (
      <div className="bg1000-white h-screen">
        <DetailRoom id={id} />
      </div>
    );
  }

  if (displayState === DisplayEnum.DETAIL_CATEGORY_FOOD) {
    const separators = ["/"];
    const newPath = path.split(
      new RegExp("[" + separators.join("") + "]", "g")
    );
    const username = newPath[2];
    const id = newPath[5];
    return (
      <div className="bg1000-white h-screen">
        <DetailCategoryFood username={username} id={id} />
      </div>
    );
  }

  if (displayState === DisplayEnum.DETAIL_FOOD) {
    const separators = ["/"];
    const newPath = path.split(
      new RegExp("[" + separators.join("") + "]", "g")
    );
    const username = newPath[2];
    const id = newPath[5];
    return (
      <div className="bg1000-white h-screen">
        <DetailFood username={username} id={id} />
      </div>
    );
  }
  if (displayState === DisplayEnum.SEARCH_FOOD) {
    const separators = ["/"];
    const newPath = path.split(
      new RegExp("[" + separators.join("") + "]", "g")
    );
    const username = newPath[2];
    return (
      <div className="bg1000-white h-screen">
        <SearchFood username={username} />
      </div>
    );
  }
  if (displayState === DisplayEnum.DETAIL_CATEGORY_SOUVENIR) {
    const separators = ["/"];
    const newPath = path.split(
      new RegExp("[" + separators.join("") + "]", "g")
    );
    const username = newPath[2];
    const id = newPath[5];
    return (
      <div className="bg1000-white h-screen">
        <DetailCategorySouvenir username={username} id={id} />
      </div>
    );
  }

  if (displayState === DisplayEnum.DETAIL_SOUVENIR) {
    const separators = ["/"];
    const newPath = path.split(
      new RegExp("[" + separators.join("") + "]", "g")
    );
    const username = newPath[2];
    const id = newPath[5];
    return (
      <div className="bg1000-white h-screen">
        <DetailSouvenir username={username} id={id} />
      </div>
    );
  }
  if (displayState === DisplayEnum.SEARCH_SOUVENIR) {
    const separators = ["/"];
    const newPath = path.split(
      new RegExp("[" + separators.join("") + "]", "g")
    );
    const username = newPath[2];
    return (
      <div className="bg1000-white h-screen">
        <SearchSouvenir username={username} />
      </div>
    );
  }

  if (displayState === DisplayEnum.DETAIL_REWARD) {
    return (
      <div className="bg1000-white h-screen">
        <DetailReward />
      </div>
    );
  }

  if (displayState === DisplayEnum.DETAIL_ORDER_FOOD) {
    const separators = ["/"];
    const newPath = path.split(
      new RegExp("[" + separators.join("") + "]", "g")
    );
    const id = newPath[3];
    return (
      <div className="bg1000-white h-screen">
        <DetailOrderFood id={id} />
      </div>
    );
  }
  if (displayState === DisplayEnum.PAYMENT_ORDER_FOOD) {
    const separators = ["/"];
    const newPath = path.split(
      new RegExp("[" + separators.join("") + "]", "g")
    );
    const id = newPath[3];
    return (
      <div className="bg1000-white h-screen">
        <PaymentOrderFood id={id} />
      </div>
    );
  }
  if (displayState === DisplayEnum.SUCCESS_PAYMENT_ORDER_FOOD) {
    const separators = ["/"];
    const newPath = path.split(
      new RegExp("[" + separators.join("") + "]", "g")
    );
    const id = newPath[3];
    return (
      <div className="bg1000-white h-screen">
        <SuccessPaymentOrderFood id={id} />
      </div>
    );
  }

  if (displayState === DisplayEnum.PAYMENT_ORDER_ROOM) {
    const separators = ["/"];
    const newPath = path.split(
      new RegExp("[" + separators.join("") + "]", "g")
    );
    const id = newPath[3];
    return (
      <div className="bg1000-white h-screen">
        <PaymentOrderRoom id={id} />
      </div>
    );
  }
  if (displayState === DisplayEnum.SUCCESS_PAYMENT_ORDER_ROOM) {
    const separators = ["/"];
    const newPath = path.split(
      new RegExp("[" + separators.join("") + "]", "g")
    );
    const id = newPath[3];
    return (
      <div className="bg1000-white h-screen">
        <SuccessPaymentOrderRoom id={id} />
      </div>
    );
  }
  if (displayState === DisplayEnum.DETAIL_ORDER_SOUVENIR) {
    const separators = ["/"];
    const newPath = path.split(
      new RegExp("[" + separators.join("") + "]", "g")
    );
    const id = newPath[3];
    return (
      <div className="bg1000-white h-screen">
        <DetailOrderSouvenir id={id} />
      </div>
    );
  }
  if (displayState === DisplayEnum.PAYMENT_ORDER_SOUVENIR) {
    const separators = ["/"];
    const newPath = path.split(
      new RegExp("[" + separators.join("") + "]", "g")
    );
    const id = newPath[3];
    return (
      <div className="bg1000-white h-screen">
        <PaymentOrderSouvenir id={id} />
      </div>
    );
  }
  if (displayState === DisplayEnum.SUCCESS_PAYMENT_ORDER_SOUVENIR) {
    const separators = ["/"];
    const newPath = path.split(
      new RegExp("[" + separators.join("") + "]", "g")
    );
    const id = newPath[3];
    return (
      <div className="bg1000-white h-screen">
        <SuccessPaymentOrderSouvenir id={id} />
      </div>
    );
  }
  if (displayState === DisplayEnum.DETAIL_ORDER_WALLET) {
    const separators = ["/"];
    const newPath = path.split(
      new RegExp("[" + separators.join("") + "]", "g")
    );
    const id = newPath[3];
    return (
      <div className="bg1000-white h-screen">
        <DetailOrderWallet id={id} />
      </div>
    );
  }
  if (displayState === DisplayEnum.PAYMENT_ORDER_WALLET) {
    const separators = ["/"];
    const newPath = path.split(
      new RegExp("[" + separators.join("") + "]", "g")
    );
    const id = newPath[3];
    return (
      <div className="bg1000-white h-screen">
        <PaymentOrderWallet id={id} />
      </div>
    );
  }
  if (displayState === DisplayEnum.DETAIL_ORDER) {
    const separators = ["/"];
    const newPath = path.split(
      new RegExp("[" + separators.join("") + "]", "g")
    );
    const id = newPath[2];
    return (
      <div className="bg1000-white h-screen">
        <DetailOrder id={id} />
      </div>
    );
  }
  if (displayState === DisplayEnum.EDIT_PROFILE) {
    const separators = ["/"];
    const newPath = path.split(
      new RegExp("[" + separators.join("") + "]", "g")
    );
    const id = newPath[3];
    return (
      <div className="bg1000-white h-screen">
        <EditProfile id={id} />
      </div>
    );
  }

  return <ErrorPage statusCode={404} />;
};

export default Custom404;
